import React from 'react';
import EmailIcon from '../../static/images/icons/email.svg';
import PhoneIcon from '../../static/images/icons/phone.svg';

const ContactCard = ({ type = 'phone', content }) => (
  <div className="call-container h-100">
    {type === 'phone' ? <PhoneIcon className="icon"></PhoneIcon> : <EmailIcon className="icon"></EmailIcon>}
    <div className="d-flex flex-column align-items-center label">
      <span style={{ fontWeight: 700 }}>{type === 'phone' ? 'Phone' : 'Email'}</span>
      <span style={{ whiteSpace: 'nowrap' }}>{content}</span>
    </div>
  </div>
);

export default ContactCard;
