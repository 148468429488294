import { graphql } from 'gatsby';
import React from 'react';
import ContactCard from '../components/ContactCard';
import Layout from '../components/Layout';
import Title from '../components/Title';

const Contact = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  const { days, email, phone } = data.contactJson;
  return (
    <Layout bodyClass="page-contact">
      <Title>
        <h1>{title}</h1>
      </Title>
      <div className="container mb-6">
        <div className="row justify-content-center gap-3 flex-wrap flex-md-nowrap">
          <div className="col-md-6 col-12">
            <div className="h-100 d-flex justify-content-end justify-content-sm-center gap-2">
              <ContactCard type="phone" content={phone}></ContactCard>
              <ContactCard
                type="email"
                content={
                  <a href={`mailto:${email}`} target="__blank">
                    {email}
                  </a>
                }
              ></ContactCard>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="content">
              <div className="content-container p-2 pt-0">
                <div className="row head">
                  <div className="col-5 p-0">Day</div>
                  <div className="col-7 p-0">Opening Hours</div>
                </div>
                {days.map((item, i) => (
                  <div key={item.day} className="row body">
                    <div className="col-5 p-0">
                      <div className={`item ${i === days.length - 1 ? 'no-border' : ''}`}>{item.day}</div>
                    </div>
                    <div className="col-7 p-0">
                      <div className={`item ${i === days.length - 1 ? 'no-border' : ''} ${item.time === 'Closed' ? 'close' : ''}`}>{item.time}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
      }
      fields {
        slug
      }
      html
    }
    contactJson {
      days {
        day
        time
      }
      email
      phone
    }
  }
`;

export default Contact;
